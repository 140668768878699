import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMobileDetection } from '../hook/useMobileDetection';

import { useContext, useEffect, useState } from 'react';
import { formatter } from '../resources/funciones';
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md"
import { ContadorProducto } from './ContadorProducto';
import { CarritoContext } from '../context/CarritoContext';
import { ProductoContext } from '../context/ProductoContext';

export const ProductoDescripcion = () => {

    const { aniadirProducto,aniadirPack, setMostrarCarrito } = useContext(CarritoContext)
    const { listaProductos } = useContext(ProductoContext)

    const isMobile = useMobileDetection();
    const location = useLocation()
    const idProducto = location.pathname.split('/').pop()

    const [producto, setProducto] = useState({})
    const [existeProducto, setExisteProducto] = useState(false)

    const [contador, setContador] = useState(1)

    /* useEffect(()=>{console.log(producto)},[producto]) */

    let colorPrev = "#365865"
    let colorNext = "#365865"

    const indice = listaProductos.findIndex(p => p?.ProdCod == idProducto);

    let productoAnterior = {};
    let productoPosterior = {}
    if (indice > 0) {
        productoAnterior = listaProductos[indice - 1];
    } else {
        productoAnterior = listaProductos[0]
        colorPrev = "#808B96"
    }

    if (indice < listaProductos.length - 1) {
        productoPosterior = listaProductos[indice + 1];
    } else {
        productoPosterior = listaProductos[indice]
        colorNext = "#808B96"
    }

    const [showModal, setShowModal] = useState(false);
    const [urlImage, setUrlImage] = useState(null);

    const openImagen = (url) => {
        setUrlImage(url);
        setShowModal(true);
    }

    const navigate = useNavigate()

    const realizarCompra = (producto, contador) => {
        aniadirProducto(producto, contador)
        navigate("/carrito")
        setMostrarCarrito(false)
    }

    useEffect(() => {

        const productoEncontrado = listaProductos.find(p => p?.ProdCod == idProducto)
        if (!productoEncontrado) {
            setProducto({})
            setExisteProducto(false)
        } else {
            setProducto(productoEncontrado)
            setExisteProducto(true)
        }
    }, [productoAnterior, productoPosterior])

    return (
        <div>
            <div className="container">
                <div className="row" >
                    <div aria-label="breadcrumb" className="col-md-9 mt-3">
                        <ol className="breadcrumb text-md-start text-center">
                            <Link className="breadcrumb-item" to={`/inicio`} style={{ color: "#365865" }}>Inicio</Link>
                            <Link className="breadcrumb-item" to={`/tienda`} style={{ color: "#365865" }}>Tienda</Link>
                            <Link className="breadcrumb-item active" aria-current="page">{producto?.ProdNom}</Link>
                        </ol>
                    </div>
                    <div className="col-md-3 mt-3 text-md-end text-center">
                        <div className={`mb-1 d-flex align-items-center ${isMobile ? "justify-content-center" : "justify-content-end"}`}>
                            <Link className={`me-2`} to={`/producto/${productoAnterior?.ProdCod}`} style={{ color: colorPrev, pointerEvents: indice == 0 ? "none" : "", display: "flex", alignItems: "center" }}><MdArrowBackIosNew />Previo</Link>
                            |
                            <Link className={`ms-2 active`} to={`/producto/${productoPosterior?.ProdCod}`} style={{ color: colorNext, pointerEvents: indice == listaProductos.length - 1 ? "none" : "", display: "flex", alignItems: "center" }}>Próximo <MdArrowForwardIos /></Link>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container" style={{ minHeight: isMobile ? "auto" : "calc(100vh - 161px)" }}>
                {
                    existeProducto ? (
                        <>

                            <div className="row" style={{ minHeight: isMobile ? "auto" : "calc(100vh - 161px)" }}>
                                <div className="col-12 col-md-6 mb-2">
                                    {
                                        producto?.ProdNue ? (
                                            <div className="bg-secundario" style={{ textAlign: "center", color: "white", fontSize: "0.9rem" ,borderRadius:".5rem",margin:".1rem"}}><span>Nueva Presentación</span></div>
                                        ) : (
                                            <div style={{ fontSize: "14.4px" }}>&nbsp;</div>
                                        )
                                    }
                                    <div className="d-flex justify-content-center" data-mdb-ripple-color="light" style={{ height: "90%", width: "90%" }}>

                                        <img className="img-card my-2 mx-2 h-100" src={producto?.ProdImg} onClick={() => openImagen(producto?.ProdImgBig)} style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#exampleModal" />

                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h5 className="text-start mt-1" style={{ fontSize: "26px" }}>{producto?.ProdNom}</h5>
                                    <h6 className="text-start mt-3">
                                        {
                                            (!producto?.ProdPrecD) ? (
                                                <strong style={{ fontSize: "24px", color: "#052F3E" }}>{formatter.format(producto?.ProdPrecN)}</strong>
                                            ) : (
                                                <>
                                                    <s style={{ fontSize: "18px", color: "rgb(128,128,128)" }}>{formatter.format(producto?.ProdPrecN)}</s>
                                                    <strong className="ms-2" style={{ fontSize: "24px", color: "#052F3E" }}>{formatter.format(producto?.ProdPrecD)}</strong>
                                                </>
                                            )
                                        }


                                    </h6>
                                    <div className="info-cantidad mt-3">
                                        <h5>Cantidad</h5>
                                        <div className="mt-3" style={{ width: '100px' }}>
                                            <ContadorProducto idProducto={idProducto} contador={contador} setContador={setContador} />
                                        </div>

                                    </div>
                                    <div className="botones-comprar mt-4">
                                        <button type="button" className="btn bg-principal w-100 text-white" onClick={() =>producto.ProdPack? aniadirPack(producto,contador):aniadirProducto(producto, contador)}>Agregar al carrito</button>
                                        {! producto.ProdPack && <button type="button" className="btn bg-secundario w-100 text-white" onClick={() => realizarCompra(producto, contador)}>Realizar compra</button>}
                                    </div>
                                    <div className="info-descripcion">
                                        <h5 className="mt-3">INFORMACIÓN DEL PRODUCTO</h5>
                                        <p className="mt-3" style={{ textAlign: "justify", color: "#555555" }}>{producto?.ProdDes}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content" style={{ height: "150vh" }}>
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
                                        </div>
                                        <div className="modal-body d-flex justify-content-center" style={{ height: "100%", width: "100%" }}>
                                            <img className="my-2 mx-2 h-100 img-zoom" src={urlImage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <h2>No hay producto</h2>
                        </div>
                    )
                }

            </div>

            <div className="div-franja" id="franjaimagen"></div>
        </div>
    )
}
